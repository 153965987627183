import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useSearch } from '@hooks/useSearch';
import { SEARCH_INDEXES } from '@lib/constants';

const INDEXES_DISPLAYED = [
  SEARCH_INDEXES.PRODUCTS,
  SEARCH_INDEXES.CATEGORIES,
  SEARCH_INDEXES.BLOGS,
] as Array<(typeof SEARCH_INDEXES)[keyof typeof SEARCH_INDEXES]>;

export function Custom404Links({ query }: { query: string }) {
  const { isLoadingMore, data } = useSearch({
    index: '*',
    query,
    pageSize: 3,
  });

  return isLoadingMore ? (
    <Paragraph>Unfortunately, we can't find that page.</Paragraph>
  ) : (
    <>
      <Paragraph>
        Unfortunately, we can't find that page. We suggest heading to our{' '}
        <TextLink href="/">homepage</TextLink>
        {data && data.length > 0 ? ', or trying one of the links below: ' : '.'}
      </Paragraph>
      <ul className="list-inside list-disc transition-all">
        {data &&
          data
            .reduce((acc, curr) => {
              if (INDEXES_DISPLAYED.includes(curr.index)) {
                return [...acc, ...curr.hits];
              }
              return acc;
            }, [] as Array<any>)
            .map((result, index) => (
              <li key={index}>
                <TextLink href={result.slug}>
                  {result.name ?? result.title}
                </TextLink>
              </li>
            ))}
      </ul>
    </>
  );
}
